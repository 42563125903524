import React from 'react';
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import Dotdotdot from 'react-dotdotdot'

const episodesInfo = (number) => {
    return number === 1 ? 'odcinek' : (number > 1 && number < 5 ? 'odcinki' : 'odcinków');
};

function Box(props) {

    const {title, episodes, isNew, imageUrl, id} = props;

    return (
        <Link to={"/" + id}>
            <div className='cardDimension'>
                <Card>
                    <Card.Img variant="top" src={imageUrl}/>
                </Card>
                <Card.Title className='boxTitle'>
                    <Dotdotdot clamp={2}>
                    {title}
                    </Dotdotdot>
                </Card.Title>
                <Card.Text>
                    <span className='boxEpisodes'>{episodes} {episodesInfo(episodes)}</span>
                    {isNew === 'true' ? <Badge variant="danger" className='px-2 fs-badge'>Nowy</Badge> : ''}
                </Card.Text>
            </div>
        </Link>
    )
}


export default Box

