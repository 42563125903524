import React, {useRef} from 'react';
import {playIconWhite, stopIconWhite} from "../images";
import {setPlayer} from "../actions";
import {connect} from "react-redux";
import Badge from "react-bootstrap/Badge";

function TopPlayer(props) {

    const {play, title, seek, setTime, currentTime} = props;

    const seekElement = useRef(null);

    const togglePlay = () => {

        props.setPlayer({...props.player, play: !play});
    };

    const setTimeLocal = (e) => {

        setTime(e.nativeEvent)

    };


    return (
        <div className={title ? 'd-none d-lg-block' : 'd-none'}>
            <div className='topPlayer d-none d-lg-flex' style={{zIndex: '100'}}>
                <div className='topRedPlayerSection' onClick={togglePlay}>
                    <img src={play ? stopIconWhite : playIconWhite} alt="play icon"/>
                </div>
                <div className='topBlackPlayerSection'>
                    <p className='font-weight-bold'>{props.player.podcastTitle} &nbsp;
                    <span className='font-weight-normal'>{title}</span>
                     {title === 'Radio Kielce' || title === 'Folk Radio' ?
                         <Badge variant="danger" className='radio-badge'>Na żywo</Badge> : null }
                    </p>
                    {title !== 'Radio Kielce' && title !== 'Folk Radio' ? <div className='progressPlayerDesktop' ref={seekElement}>
                        {seek ? <span>{currentTime}</span> : null}
                        {seek ? <span>{props.player.duration}</span> : null}
                        <div className='progressPlayerDesktopWhite' style={seek ? {width: seek + '%'} : null}/>
                    </div> : null}

                    <div className='seekVisibleDesktop' onClick={e => setTimeLocal(e)}/>
                </div>
            </div>
        </div>
    )
}


const mapDispatchToProps = dispatch => ({
    setPlayer: player => dispatch(setPlayer(player))
});

export default connect(null, mapDispatchToProps)(TopPlayer)
