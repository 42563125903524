import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Home from "./home";
import Podcast from "./podcast";
import Header from "./components/header";
import Footer from "./components/footer";



function App() {

    return (
        <div className="App">
            <Router>
                <Header/>
                <Switch>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route path="/:id">
                        <Podcast/>
                    </Route>
                </Switch>
                <Footer/>
            </Router>
        </div>
    );
}


export default App
