import React from 'react';
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";


function RadioBox(props) {

    const {title, imageUrl} = props;

    return (
        <div className='cardDimension' style={{cursor: 'pointer'}}>
            <Card style={{border: 'none'}}>
                <Card.Img variant="top" src={imageUrl}/>
            </Card>
            <Card.Title className='boxTitle'>{title}</Card.Title>
            <Card.Text>
                <span className='boxEpisodes'>
                    <Badge className='mr-2 newBadge' variant="danger">Na żywo</Badge>
                </span>
            </Card.Text>
        </div>
    )
}


export default RadioBox


