import React, {useRef} from 'react';
import {playIconWhite, stopIconWhite} from "../images";
import {setPlayer} from "../actions";
import { connect } from "react-redux";
import Badge from "react-bootstrap/Badge";

function BottomPlayer(props) {

    const {play, title, seek, currentTime, setTime} = props;

    const togglePlay = () => {
        props.setPlayer({...props.player, play: !play});

    };

    const seekElement = useRef(null);

    const setTimeLocal = (e) => {

        setTime(e.nativeEvent)

    };


    return (
        <div className={title ? 'd-block' : 'd-none'}>
            <div className='bottomPlayer d-flex d-lg-none' style={{zIndex: '100'}}>
                <div className='redPlayerSection'>
                    <img src={play ? stopIconWhite : playIconWhite} alt="play icon" onClick={togglePlay}/>
                </div>
                <div className='blackPlayerSection'>
                    <p>{title}
                        {title === 'Radio Kielce' || title === 'Folk Radio' ?
                            <Badge variant="danger" className='radio-badge'>Na żywo</Badge> : null }</p>
                    <p>{props.player.podcastTitle}</p>
                    {title !== 'Radio Kielce' && title !== 'Folk Radio' ? <div className='progressPlayerMobile' ref={seekElement}>
                        {seek ? <span>{currentTime}</span> : null }
                        {seek ? <span>{props.player.duration}</span> : null}
                        <div className='progressPlayerMobileWhite' style={seek ? {width: seek + '%'} : null}/>
                    </div> : null}
                    <div className='seekVisibleDesktop' onClick={e => setTimeLocal(e)}/>
                </div>
            </div>
        </div>
    )
}


const mapDispatchToProps = dispatch => ({
    setPlayer: player => dispatch(setPlayer(player))
});

export default connect(null, mapDispatchToProps)(BottomPlayer)
