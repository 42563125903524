import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import Box from "./box";
import {fetchAll} from "../api/podcastsAPI";
import Loader from 'react-loader-spinner'


const compare = (a, b) => {
    if (a.plays_count < b.plays_count) {
        return -1;
    }
    if (a.plays_count > b.plays_count) {
        return 1;
    }
    return 0;
};


function PopularBox(props) {

    const {visibleClass, count, xl} = props;

    const [popular, setPopular] = useState([]);

    const [loading, setLoading] = useState(true);

    const getPopular = () => {
        fetchAll()
            .then(response => {
                if (response) {
                    setPopular(response);
                } else {
                    setPopular([]);
                }
                setLoading(false);
            })
            .catch(() => {
                setPopular([]);
                setLoading(false);
            })
    };

    useEffect(() => {
        getPopular();
    }, []);

    useEffect(() => {
        if (popular) {
            popular.sort(compare).reverse();
        }
    }, [popular]);


    return (
        <Row className={`flex-wrap ${visibleClass}`}>
            <Col xs={12}>
                <h3 className='popularTitle'>Popularne</h3>
            </Col>


            {loading ?
                <Col xs={12} className='d-flex justify-content-center align-items-center' style={{height: '300px'}}>
                    <Loader
                        type="Oval"
                        color="#000"
                        height={100}
                        width={100}

                    />
                </Col> :

                popular.map((item, index) =>

                    index < count ? <Col xs={6} sm={4} md={3} lg={3} xl={xl} key={index}>
                        <Box title={item.title}
                             episodes={item.episodes}
                             isNew={item.isNew}
                             imageUrl={item.image_original_url}
                             id={item.friendly_url}
                        />
                    </Col> : null
                )
            }

        </Row>
    )
}


export default PopularBox

