const initialState = {
    categoryName: ''
};

export const category = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CATEGORY':
            return {
                categoryName: action.category.categoryName,
            };
        default:
            return state
    }
}
