import React, {useState} from 'react';
import Autosuggest from 'react-autosuggest';
import InputGroup from "react-bootstrap/InputGroup";
import {Link} from "react-router-dom";

function Autocomplete(props) {

    const {podcasts} = props;


    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);


    const getSuggestions = value => {

        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : podcasts.filter(podcast => {

            if(podcast.title.toLowerCase().includes(inputValue)){
                return podcast.title.toLowerCase().includes(inputValue)
            }else{
                return podcast.description ? podcast.description.replace(/[^\w\s]/gi, '').toLowerCase().includes(inputValue) : null
            }

            }
        );
    };

    const renderSuggestion = suggestion => (
        <Link to={'/' + suggestion.friendly_url}>
            <img className='autocomplete-img' src={suggestion.imageUrl} alt={suggestion.title}/>
            {suggestion.title}
        </Link>
    );

    const getSuggestionValue = suggestion => suggestion.title;

    const onChange = (event, {newValue}) => {
        setValue(newValue);
    };


    const inputProps = {
        placeholder: 'Szukaj Podcastu',
        value,
        style: styles.searchInput,
        className: 'form-control search-dimension',
        onChange: onChange
    };

    const onSuggestionsFetchRequested = ({value}) => {
        setSuggestions(getSuggestions(value))
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    };

    return (
        <InputGroup className="mb-2">
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
        </InputGroup>
    )
}


export default Autocomplete

const styles = {
    searchInput: {
        backgroundColor: '#322F2F',
        maxWidth: '394px',
        borderRadius: '17px',
        color: '#fff',
        height: '44px',
        border: '0'
    }
};
