const initialState = {
    source: '',
    id: '',
    title: '',
    play: false,
    duration: null,
    podcastTitle: ''
};

export const player = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PLAYER':
            return {
                source: action.player.source,
                id: action.player.id,
                title: action.player.title,
                play: action.player.play,
                duration: action.player.duration,
                podcastTitle: action.player.podcastTitle
            };
        default:
            return state
    }
}
